import React, { useState } from 'react';
import { Form, Checkbox, Input, Button, Select, Slider, Row, Col } from 'antd';
import { SearchOutlined, RollbackOutlined } from '@ant-design/icons';

import './index.css'

import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { applyFilters } from '../../features/cards/cardsActions';
import { filters as filtersApplied, sets as getSets} from '../../features/cards/cardsSlice';


const FilterComponent = ({ onFilter }) => {

  const dispatch = useDispatch()
  const filters = useSelector(filtersApplied)
  const sets = useSelector(getSets)

  useEffect(()=>{
    dispatch(applyFilters({}))
  },[])
  const [selectedFilters, setSelectedFilters] = useState({
    rarity: [],
    type: [],
    hasFoil: false,
  });

  const handleFilter = () => {
    dispatch(applyFilters(selectedFilters))
    //onFilter(selectedFilters);
  };

  const handleRarityChange = (values) => {
    setSelectedFilters({ ...selectedFilters, rarity: values });
  };

  const handleColorChange = (values) => {
    setSelectedFilters({ ...selectedFilters, color: values });
  };

  const handleSearchChange = (values) => {
    setSelectedFilters({ ...selectedFilters, search: values });
  };

  const handleTypeChange = (values) => {
    setSelectedFilters({ ...selectedFilters, type: values });
  };

  const handleAAChange = (e) => {
    setSelectedFilters({ ...selectedFilters, AA: e.target.checked });
  };

  const handleSortByChange = (values) => {
    setSelectedFilters({ ...selectedFilters, sortBy: values });
  };

  const handleSetChange = (values) => {
    setSelectedFilters({ ...selectedFilters, set: values });
  };

  const handleCostChange = (values) => {
    setSelectedFilters({ ...selectedFilters, cost: values });
  };

  const handlePowerChange = (values) => {
    setSelectedFilters({ ...selectedFilters, power: values });
  };

  const handleCategoryChange = (values) => {
    setSelectedFilters({ ...selectedFilters, category: values });
  };

  const colorsOpt = [
    { label: 'Red', value: 'RED' },
    { label: 'Green', value: 'GREEN' },
    { label: 'Blue', value: 'BLUE' },
    { label: 'Purple', value: 'PURPLE' },
    { label: 'Black', value: 'BLACK' },
    { label: 'Yellow', value: 'YELLOW' },
  ];

  const rarityOpt = [
    { label: 'Common', value: 'C' },
    { label: 'Uncommon', value: 'UC' },
    { label: 'Rare', value: 'R' },
    { label: 'Super Rare', value: 'SR' },
    { label: 'Secret Rare', value: 'SEC' },
    { label: 'Promo', value: 'P' },
    { label: 'Leader', value: 'L' }
  ];


  const categoryOpt = [
    { label: 'Leader', value: 'LEADER' },
    { label: 'Character', value: 'CHARACTER' },
    { label: 'Event', value: 'EVENT' },
    { label: 'Stage', value: 'STAGE' },
  ];

  let sortBy = selectedFilters.sortBy
  return (
    <Form className='filter-form' onFinish={handleFilter}>
      <div style={{ background: '#f0f2f5', padding: '24px', borderRadius: '8px' }}>
        <h2 style={{ marginBottom: '16px', marginTop: '0' }}>Filtri</h2>
        <Row gutter={16} align="middle">
          <Col span={4}>
            Search name:
          </Col>
          <Col span={20}>
            <Input
              placeholder="Search"
              value={selectedFilters.search}
              onChange={e => handleSearchChange(e.target.value)}
            />
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col span={4}>
            Search type:
          </Col>
          <Col span={20}>
            <Input
              placeholder="Search"
              value={selectedFilters.type}
              onChange={e => handleTypeChange(e.target.value)}
            />
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col span={4}>
            Set: 
          </Col>
          <Col span={20}>
            
            <Select 
            value={selectedFilters.set ? selectedFilters.set : '*'} 
            onChange={handleSetChange}
            style={{ width: '100%' }}
            defaultValue={'*'}
            >
              <Select.Option value="*">All</Select.Option>
              {sets.map(set => <Select.Option key={set.id} value={set.id}>{set.title}</Select.Option>)}
            </Select>
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col span={24}>
            Color:
            <Checkbox.Group style={{ width: '100%' }} options={colorsOpt} value={selectedFilters.color} onChange={handleColorChange}>
            </Checkbox.Group>
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col span={24}>
            Rarity:
            <Checkbox.Group style={{ width: '100%' }} options={rarityOpt} value={selectedFilters.rarity} onChange={handleRarityChange}>
            </Checkbox.Group>
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col span={24}>
            Category:
            <Checkbox.Group style={{ width: '100%' }} options={categoryOpt} value={selectedFilters.category} onChange={handleCategoryChange}>
            </Checkbox.Group>
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col span={24}>
            Cost: 
            <Slider range marks={{0:'0',1:'1',2:'2',3:'3',4:'4',5:'5',6:'6',7:'7',8:'8',9:'9',10:'10',}} 
              min={0} max={10} 
              defaultValue={[0,10]} 
              value={selectedFilters.cost ? selectedFilters.cost : [0,10]} 
              onChange={handleCostChange}
            />
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col span={24}>
            Power: 
            <Slider range step={1000} 
              marks={{0:'0',5000:'5000',10000:'10000',15000:'15000'}} 
              min={0} max={15000} defaultValue={[0,15000]} 
              value={selectedFilters.power ? selectedFilters.power : [0,15000]} 
              onChange={handlePowerChange}
            />
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col span={8}>
            Order: 
            <Select 
            value={sortBy ? sortBy : 'id'} 
            onChange={handleSortByChange}
            style={{ width: 120 }}
            defaultValue={'id'}
            >
              <Select.Option value="id">id</Select.Option>
              <Select.Option value="name">Name</Select.Option>
              <Select.Option value="color">Color</Select.Option>
            </Select>
          </Col>
          <Col span={4}>
            <Checkbox
              style={{ marginLeft: '16px' }}
              checked={selectedFilters.AA}
              onChange={handleAAChange}
            >
              Alt Art
            </Checkbox>
          </Col>


        </Row>
        <Row gutter={16} align="middle">
          <Col span={16}>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              style={{ width: '100%', marginLeft: '16px' }}
              htmlType="submit"
            >
              Filter
            </Button>
          </Col>
          <Col span={4}>
            <Button
              type="default"
              icon={<RollbackOutlined />}
              style={{ width: '100%',marginLeft: '5px' }}
              onClick={()=> setSelectedFilters({})}
              htmlType="submit"
            >
              Reset filters
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default FilterComponent;