import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import cardsReducer from '../features/cards/cardsSlice';
import collectionReducer from '../features/Collection/collectionSlice';
import deckReducer from '../features/Deck/deckSlice';
import builderReducer from '../features/Builder/builderSlice';

export const store = configureStore({
  reducer: {
    cards: cardsReducer,
    collection: collectionReducer,
    deck: deckReducer,
    builder: builderReducer,
    auth: authReducer,
  },
});
