import {  createSlice } from '@reduxjs/toolkit';
import { setTitle, addCard, postDeck } from './builderActions';
import { DeckBuilder } from '../../utils/deckBuilder';

const initialState = { 
  name: '',
  deck:{title:"",leader:{},list:[], don:{}}
  //cardList: []
};

export const builderSlice = createSlice({
  name: 'builder',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {}, 
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
    .addCase(setTitle, ((state, action) => {
      state.deck = {...state.deck, title:action.payload}
    }))
    .addCase(addCard, ((state, action) => {
      if (action.payload.card == null ){
        return
      }
      let card = action.payload.card
      let copy = action.payload.copy ?? 1
      state.deck = DeckBuilder.addCard(card, copy, state.deck)
    }))
    
      /*.addCase(fetchCards.pending, (state, action) => {
        state.status = 'loading';
      })*/
      .addCase(postDeck.fulfilled, (state, action) => {
      })
  },
});

//export const { increment, decrement, incrementByAmount } = cardsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const builderDeck = (state) => state.builder.deck;
export const deckName = (state) => state.builder.name;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};*/

export default builderSlice.reducer;
