import * as React from 'react';

import { UserOutlined, SettingOutlined, HomeOutlined, DatabaseOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useState } from 'react';
import { NavLink } from "react-router-dom";

import { useSelector } from 'react-redux';
import { user as userData} from '../../features/auth/authSlice';
import Logout from '../Logout';



export default function AppBar() {
  const user = useSelector(userData)
  const items = [
  {
    label: <NavLink
              to="/"
            >
              Home
            </NavLink>,
    icon: <HomeOutlined />,
  },
  {
    label: <NavLink
              to="cards"
            >
              Carte
            </NavLink>,
    icon: <DatabaseOutlined />,
  },
  {
    label: <NavLink
              to="builder"
            >
              Builder
            </NavLink>,
    icon: <DatabaseOutlined />,
  },
  ... user ?
    [
      {
        label: <NavLink
                  to="user"
                >
                  {user.username}
                </NavLink>,
        icon: <UserOutlined />,
        style: { marginLeft: 'auto' }
      },
      {
        label: <Logout><NavLink
                >
                  Logout
                </NavLink></Logout>,
        icon: <LogoutOutlined />,
        //style: { marginLeft: 'auto' }
      }
    ]
  : 
    [
      {
        label: <NavLink
                  to="login"
                >
                  Login
                </NavLink>,
        icon: <UserOutlined />,
        style: { marginLeft: 'auto' }
      }
    ]
  ,
];
  return <Menu mode="horizontal" items={items} theme={'dark'}>
  </Menu>;
}