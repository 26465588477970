import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Alert} from 'antd';

import { loginUser } from '../../features/auth/authActions'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from "react-router-dom";

const LoginElement = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { loading, loginError } = useSelector(
    (state) => state.auth
  )

  const onFinish = (values) => {
    dispatch(loginUser({username:values.username,password:values.password})).unwrap().then((result) => {
      navigate('/')
    }).catch((err) => {
    });
  };

  const renderErrorMsg = () => {
    if (!loading && loginError) {
      return <Form.Item><Alert message={loginError} type="error" /></Form.Item>;
    }
  }

  return (
        <Form
          name="localLogin"
          className="loginForm"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot">
              Forgot password
            </a>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </Form.Item>

          { renderErrorMsg() }

        </Form>
  );
};

export default LoginElement;