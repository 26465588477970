

var DeckBuilder = {

    /*
        Deck -> {leader:{},list:[], don:{}}
    */

    validCardAdd : (copy, card, deck) => {
        return true
    },

    addCard :  (card, copy, deck) => {
        if (card.rarity == 'L'){
            if (deck.leader.id && deck.leader.number != card.number && deck?.list.length > 0){
                if (window.confirm("Change leader?")){
                    deck.list =  deck?.list?.filter( listElement => card.color?.some(l=> listElement.card.color?.map(x => x.toUpperCase()).includes(l.toUpperCase())) )
                    deck.leader = card
                }else{
                    return deck
                }
            }else{
                deck.leader = card
            }            
        }else{
            const cardCount = deck?.list?.reduce((accumulator, card) => {
                return accumulator + card.copy;
              }, 0);
            
            if(cardCount >=50){
                return deck
            }
            if (copy > 0 && deck.leader.id && !deck.leader?.color?.some(l=> card?.color?.map(x => x.toUpperCase()).includes(l.toUpperCase()))){

                return deck
            } 
            let index = deck?.list?.findIndex(deckcard => deckcard.card.number == card.number)
            if (index < 0){
                deck?.list.push({card: card, copy: 0})
                index = deck?.list.length - 1
            }
            if (deck?.list[index].copy + copy >= 4){
                if(card.id == 'OP01-075'){
                    if (deck?.list[index].copy + copy >= 50){
                        deck.list[index].copy = 50
                    }else{
                        deck.list[index].copy += copy
                    }
                }else{
                    deck.list[index].copy = 4
                }
            }else if (deck?.list[index].copy + copy <= 0){
                deck.list.splice(index, 1);
            }else{
                deck.list[index].copy += copy
            }
        }

        return deck
    },

    normalizeDeck: () => {

    }

}



export {DeckBuilder};