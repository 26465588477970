import api from '../../utils/api'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'


export const fetchCards = createAsyncThunk(
  'cards/fetchCards',
  async () => {
    const response = await api.get("api/cards");
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchSets = createAsyncThunk(
  'cards/fetchSets',
  async () => {
    const response = await api.get("api/getSets");
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);


export const applyFilters = createAction('cards/applyFilters');