import React, { useEffect, useState  }  from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { homeDecks} from '../../features/Deck/deckSlice';
import { fetchDecksHome } from '../../features/Deck/deckActions';

import { List, Card } from 'antd';


import { Link } from "react-router-dom";


const { Meta } = Card;

export default function Home() {

  const dispatch = useDispatch();

  const data = useSelector(homeDecks)

  useEffect(()=>{
    dispatch(fetchDecksHome())
    }, [])

    return <div>
      <List
        //itemLayout="horizontal"
        grid={{ gutter: 16, column: 5 }}
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item>
            <Link to={"./deck/"+item._id}>
              <Card
                hoverable
                style={{color:'white'}}
                bodyStyle={{ background: 'top / cover no-repeat radial-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.9)), top / cover no-repeat url("https://opcgcdn.b-cdn.net/CARDS/EN/'+item.leader?.set + '/' + item.leader?.id + '.png")' }}
              >
                <Meta title={<b style={{color:'white'}}>{item.title}</b>} description={<b style={{color:'white'}}>{item.author}</b>} style={{minHeight: '100px'}}/>
              </Card>
            </Link>
          </List.Item>
        )}
      />
    </div>
}