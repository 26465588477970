import * as React from 'react';

import { Breadcrumb, Layout } from 'antd';

import { Outlet } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

import Appbar from "../../components/Appbar";

import useAckee from "../../utils/useAckee";

import './index.css'

const { Header, Content, Footer } = Layout;

export default function Root() {
  const location = useLocation()

	useAckee(location.pathname, location, {
		server: 'https://ackee-opcg.app.109tech.com',
		domainId: 'f020ae2d-bc20-4d95-9e7f-86f65668dcb3',
	}, {
		ignoreLocalhost: true,
    ignoreOwnVisits: true
	})

  return (
    <Layout className="layout" style={{height:"100vh"}}>
      <Header>
        <div className="logo" />
        <Appbar/>
      </Header>
      <Content style={{ padding: '25px 24px' }}>
        {/*<Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb>*/}
        <div className="site-layout-content">
          <Outlet />
        </div>        
      </Content>
      
    </Layout>
  );
}
//<Footer style={{ textAlign: 'center' }}>109Tech ©2023 Created by Giga</Footer>

