import api from '../../utils/api'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'


export const postDeck = createAsyncThunk(
  'builder/postDeck',
  async (deck, { rejectWithValue }) => {
    try{
      let postDeck = {...deck, leader: deck.leader?.id, list: deck.list.map((cardElement) => ({card:cardElement.card.id, copy:cardElement.copy}))}
      const response = await api.post("api/postDeck",postDeck);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      //return rejectWithValue("Wrong username or password")
      // return custom error message from backend if present
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
);


export const setTitle = createAction('builder/setTitle');
export const addCard = createAction('builder/addCard');