import { createSlice } from '@reduxjs/toolkit'
import { registerUser, loginUser, logout, restoreSession } from './authActions'
import jwt_decode from "jwt-decode";

const initialState = {
  loading: false,
  user: undefined, // for user object
  registerError: null,
  loginError: null,
  success: false, // for monitoring the registration process.
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    // register user
    [registerUser.pending]: (state) => {
      state.loading = true
      state.registerError = null
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      console.log('REG',payload)
      state.loading = false
      state.success = true // registration successful
      state.user = jwt_decode(payload.token).user;
      sessionStorage.setItem('token',payload.token)
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.registerError = payload
    },
    // login user
    [loginUser.pending]: (state) => {
      state.loading = true
      state.loginError = null
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true // registration successful
      state.user = jwt_decode(payload.token).user;
      sessionStorage.setItem('token',payload.token)
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.loginError = payload
    },
    // logout
    [logout.pending]: (state) => {
      state.loading = true
      state.loginError = null
    },
    [logout.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true // registration successful
      state.user = undefined;
      sessionStorage.removeItem('token')
    },
    // session restore
    [restoreSession.pending]: (state) => {
      state.loading = true
      state.loginError = null
    },
    [restoreSession.fulfilled]: (state, { payload }) => {
      state.loading = false
      try{
        state.user = jwt_decode(payload).user;
      }catch(err){
        console.log(err);
      }
      
    },
    [restoreSession.rejected]: (state, { payload }) => {
      state.loading = false
      state.loginError = "" //No error message
    },
  },
})

export const user = (state) => state.auth.user;

export default authSlice.reducer