import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import './index.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";



import Root from "./pages/Root";
import Cards from "./pages/Cards";
import DeckBuilder from "./pages/DeckBuilder";
import Deck from "./pages/Deck";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ErrorPage from "./pages/Error";
import PersistLogin from './components/PersistLogin';



const router = createBrowserRouter([
  {path: "/",
  element: <PersistLogin />,
  errorElement: <ErrorPage />,
  children: [
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        { 
          index: true, 
          element: <Home /> 
        },
        {
          path: "cards/:cardId?",
          element: <Cards />,
        },
        {
          path: "builder/:deckid?",
          element: <DeckBuilder />,
        },
        {
          path: "deck/:deckid?",
          element: <Deck />,
        },
        {
          path: "login",
          element: <Login />,
        },
      ],
    },
  ]}
]);


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
