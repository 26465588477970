import React, { useEffect }  from 'react';

import CardElement from "../../components/Card";

import { useSelector, useDispatch } from 'react-redux';
import { selectCardList, filters as appliedFilters } from '../../features/cards/cardsSlice';
import { fetchCards, fetchSets } from '../../features/cards/cardsActions';
import { builderDeck, deckName } from '../../features/Builder/builderSlice';
import { addCard, postDeck, setTitle } from '../../features/Builder/builderActions';


import { Col, List, Row, Image, Badge, Affix, Button, Input } from 'antd';
import FilterComponent from '../../components/FilterComponent';

import { CardUtils } from '../../utils/cardUtils';

import { useNavigate } from "react-router-dom";

export default function DeckBuilder() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(selectCardList)
  const filters = useSelector(appliedFilters)

  const deck = useSelector(builderDeck)
  const Name = useSelector(deckName)

  useEffect(()=>{
    dispatch(fetchSets())
    dispatch(fetchCards())
    }, [])

  return (<>
    <Row wrap={false} gutter={8}>
      <Col flex="3 1 200px" style={{overflowY: "hidden"}}>
        <FilterComponent></FilterComponent>

            <List
              pagination={{ 
              position: 'both', 
              align: 'center',
              pageSize: 50,
              hideOnSinglePage: true,
              showSizeChanger: false
            }}
              grid={{ gutter: 8, xs: 1, sm: 1, md: 1, lg: 2, xl: 4, xxl: 6 }}
              dataSource={data.filter((card)=>CardUtils.filterCard(card, filters)).sort((card1, card2)=>CardUtils.compare(card1, card2, filters))}
              column={3}
              renderItem={(item) => (
                <List.Item>
                  <Image
                    preview={false}
                    onClick={() => {
                      dispatch(addCard({card: item, copy:1}))
                    }}
                    src={'https://opcgcdn.b-cdn.net/CARDS/EN/'+ item.set + '/' + item.id + '.png'}
                    fallback="https://opcgcdn.b-cdn.net/ASSETS/cardback.png"
                  />
                </List.Item>
              )}
              rowKey={(item) => (item.id)}
              header = <div></div>
            />

        
      </Col>
      <Col flex="2 1 300px" >
        <Affix offsetTop={10}>
        <div style={{ background: '#f0f2f5', padding: '24px', borderRadius: '8px', height: "100%", width: "100%"}}>
            <Row><h3>Leader:</h3></Row>
            <Row gutter={16}>
              <Col span={6}>
                <Image
                  preview={false}
                  onClick={() => {
                    alert("Click")
                  }}
                  src={'https://opcgcdn.b-cdn.net/CARDS/EN/'+ deck?.leader?.set + '/' + deck?.leader?.id + '.png'}
                  fallback="https://opcgcdn.b-cdn.net/ASSETS/cardback.png"
                />
              </Col>
              <Col span={18}>
                <Row gutter={16} align="middle">
                  <Col flex="none">
                    Title:
                  </Col>
                  <Col flex="auto">
                    <Input
                      placeholder="Title"
                      value={deck?.title}
                      onChange={e => dispatch(setTitle(e.target.value))}
                    />
                  </Col>
                </Row>
                <br></br>
                <Row gutter={16} align="middle">
                  <Button type="primary" onClick={()=>dispatch(postDeck(deck)).unwrap().then((result) => {navigate('/deck/'+result._id)})}>postDeck</Button>
                </Row>
              </Col>
            </Row>
            <Row><h3>Deck: <Badge color='geekblue' showZero offset={[0,0]} count={deck?.list.reduce((accumulator, currentCard) => accumulator + currentCard.copy, 0)}></Badge></h3></Row>
            <Row>
              <Col span={24}>
                <List
                  grid={{ gutter: 8, xs: 2, sm: 2, md: 2, lg: 3, xl: 8, xxl: 8 }}
                  dataSource={deck?.list}
                  column={3}
                  renderItem={(item) => (
                    <List.Item>
                      <Badge color='geekblue' offset={[-5,5]}  count={item.copy} key={'decklist-'+item.card.id}>
                        <Image
                          preview={false}
                          onClick={() => {
                            dispatch(addCard({card: item.card, copy:-1}))
                          }}
                          src={'https://opcgcdn.b-cdn.net/CARDS/EN/'+ item.card.set + '/' + item.card.id + '.png'}
                          fallback="https://opcgcdn.b-cdn.net/ASSETS/cardback.png"
                        />
                      </Badge>
                    </List.Item>
                  )}
                  rowKey={(item) => (item.id)}
                  showHeader={false}
                  showZero={false}
                />
              </Col>
            </Row>
            <Row><h3>Stats:</h3></Row>
            <Row>
              <Col span={24}>
                
              </Col>
            </Row>
        </div>
        </Affix>
      </Col>
    </Row>
  </>
  );
}