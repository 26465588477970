import React from 'react';
import { Col, Row  } from 'antd';


import LoginElement from "../../components/Login";
import RegisterElement from "../../components/Register";

export default function ButtonAppBar() {

  return (
    <Row gutter={{
      xs: 8,
      sm: 16,
      md: 24,
      lg: 32,
    }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <LoginElement />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <RegisterElement />
      </Col>
    </Row>
  );
}