import React from 'react';

import { logout } from '../../features/auth/authActions'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from "react-router-dom";

const Logout = ({children}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(logout()).unwrap().then((result) => {
      navigate(-1)
    }).catch((err) => {
    });
  };

  return (<span onClick={onClick} >{children}</span>);
};

export default Logout;