

var CardUtils = {

    filterCard: (card, filters) => {
      if(!filters){
        return true
      }
  
      if(filters.set && filters.set !== '*' && card.set !== filters.set){
        return false
      }
  
      if(filters.rarity && filters.rarity.length > 0 && !filters.rarity.includes(card.rarity)){
        return false
      }

      if(filters.category && filters.category.length > 0 && !filters.category.includes(card.category)){
        return false
      }
  
      if(filters.color && filters.color.length > 0 && !filters.color.some(r=> card?.color?.map(x => x.toUpperCase()).includes(r.toUpperCase()))){
        return false
      }
  
      if(filters.search && filters.search.length > 0 && !(card.name.toLowerCase().indexOf(filters.search.toLowerCase()) !== -1)){
        return false
      }
  
      if(filters.type && filters.type.length > 0 && !(card.type.toLowerCase().indexOf(filters.type.toLowerCase()) !== -1)){
        return false
      }
  
      if(filters.AA && !card.id.includes('_P')){
        return false
      }

      if(filters.cost && card.category != 'LEADER' && (card.cost < filters.cost[0] || card.cost > filters.cost[1])){
        return false
      }

      if(filters.power && card.category != 'EVENT' && card.category != 'STAGE' && (card.power < filters.power[0] || card.power > filters.power[1])){
        return false
      }
    

      
  
      return true
    },
  
    compare: (card1, card2, filters) => {

      if(filters.sortBy == 'id'){
        if(card1.id > card2.id){
          return 1
        }else{
          return -1
        }
      }
  
      if(filters.sortBy == 'name'){
        if(card1.name > card2.name || (card1.name == card2.name && card1.id > card2.id)){
          return 1
        }else{
          return -1
        }
      }
  
      var colorOrder = ['RED', 'GREEN', 'BLUE', 'PURPLE', 'BLACK', 'YELLOW']

      if(filters.sortBy == 'color'){
        const cc1 = colorOrder.indexOf(card1.color[0].toUpperCase())
        const cc2 = colorOrder.indexOf(card2.color[0].toUpperCase())
        if(cc1>cc2 || (cc1==cc2 && card1.id > card2.id)){ //color order is reversed
          return 1
        }else{ 
          return -1
        }
      }
  
  
  
      if(card1.id > card2.id){
        return 1
      }else{
        return -1
      }
    }
}



export {CardUtils};