import api from '../../utils/api'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'

export const fetchDeck = createAsyncThunk(
  'deck/getDeck',
  async (deckId) => {
    const response = await api.get("api/getDeck/"+deckId??'');
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchDecksHome = createAsyncThunk(
  'deck/fetchDecksHome',
  async (deckId) => {
    const response = await api.get("api/getDecks");
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);


export const setName = createAction('builder/setName');
export const addCard = createAction('builder/addCard');