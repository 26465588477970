import React, { useEffect }  from 'react';


import { useSelector, useDispatch } from 'react-redux';
import { selectCardList } from '../../features/cards/cardsSlice';

import { selectDeck } from '../../features/Deck/deckSlice';
import { fetchDeck } from '../../features/Deck/deckActions';


import { Col, List, Row, Image, Badge, Affix, Button } from 'antd';

import { useParams } from "react-router-dom";


export default function Deck() {

  const dispatch = useDispatch();

  const cards = useSelector(selectCardList)

  const deck = useSelector(selectDeck)

  let { deckid } = useParams();

  useEffect(()=>{
    if(deckid)
      dispatch(fetchDeck(deckid))
    }, [])
    return (<>
      <Col flex="2 1 300px" >
        <div style={{ background: '#f0f2f5', padding: '24px', borderRadius: '8px', height: "100%", width: "100%"}}>
            <Row>Leader:</Row>
            <Row>
              <Col span={6}>
                <Image
                  key={deck._id}
                  preview={true}
                  src={'https://opcgcdn.b-cdn.net/CARDS/EN/'+ deck?.leader?.set + '/' + deck?.leader?.id + '.png'}
                  fallback="https://opcgcdn.b-cdn.net/ASSETS/cardback.png"
                />
              </Col>
            </Row>
            <Row>Deck:</Row>
            <Row>
              <Col span={24}>
                <List
                  grid={{ gutter: 8, xs: 2, sm: 2, md: 2, lg: 3, xl: 8, xxl: 8 }}
                  dataSource={deck?.list}
                  column={3}
                  renderItem={(item) => (
                    <List.Item>
                      <Badge color='geekblue' offset={[-5,5]}  count={item.copy} key={'decklist-'+item.card.id}>
                        <Image
                          preview={true}
                          src={'https://opcgcdn.b-cdn.net/CARDS/EN/'+ item.card.set + '/' + item.card.id + '.png'}
                          fallback="https://opcgcdn.b-cdn.net/ASSETS/cardback.png"
                        />
                      </Badge>
                    </List.Item>
                  )}
                  rowKey={(item) => (item.id)}
                  header = <div></div>
                />
              </Col>
            </Row>
            <Row>Stats:</Row>
          
        </div>
      </Col>
  </>
  );
}