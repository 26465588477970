import {  createSlice } from '@reduxjs/toolkit';
import { fetchCards, fetchSets, applyFilters } from './cardsActions';

const initialState = {
  cardList: [],
  status: 'idle',
  filters: {},
  sets: []
};

export const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {}, 
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(applyFilters, ((state, action) => {
        state.filters = action.payload
      }))
      .addCase(fetchCards.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cardList = [...action.payload];
      })
      .addCase(fetchSets.fulfilled, (state, action) => {
        state.sets = [...action.payload];
      })
  },
});

//export const { increment, decrement, incrementByAmount } = cardsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCardList = (state) => state.cards.cardList;
export const filters = (state) => state.cards.filters;
export const sets = (state) => state.cards.sets;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};*/

export default cardsSlice.reducer;
