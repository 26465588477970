import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    console.log(err)
    if (! originalConfig.url.startsWith('/auth/') && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        
        try {
          await refresh()
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }

      }
    }else{
      return Promise.reject("Login error");
    }
    return Promise.reject(err);
  }
);


const refresh = async () => {
  try {
    const rs = await instance.post("/auth/refreshToken",{},{ withCredentials: true });

    const accessToken = rs.data?.token;
    sessionStorage.setItem('token',accessToken)

    return accessToken
  } catch (err) {
    throw new Error(err)
  }
}

export default instance;

export {refresh}