import * as React from 'react';

import { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";

import { restoreSession } from '../../features/auth/authActions'
import { useDispatch, useSelector } from 'react-redux'

export default function PersistLogin() {
  const [isLoading, setIsLoading] = useState(true)
  let persist = true

  const dispatch = useDispatch()

  useEffect(()=>{
    let isMounted = true;
    const resSession = async () => {
      try {
        await dispatch(restoreSession())
      } catch (err) {
        console.error(err)
      }finally{
        isMounted && setIsLoading(false)
      }
    }

    resSession()

    return () => isMounted = false;
  },[])

  useEffect(() => {
    //console.log(`isLoading: ${isLoading}`)
    //console.log(`aT: ${JSON.stringify(sessionStorage.getItem('token'))}`)
  }, [isLoading])

  return (
    <>
        {!persist
            ? <Outlet />
            : isLoading
                ? <></>//LOADING
                : <Outlet />
        }
    </>
)
}