import {  createSlice } from '@reduxjs/toolkit';
import { removeCardCollection, addCardCollection, fetchCollection } from './collectionActions';


import api from '../../utils/api'


const initialState = { 
  collection:{}
};

export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {}, 
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
    .addCase(removeCardCollection, ((state, action) => {
      if (state.collection[action.payload]??0 >0){
        state.collection[action.payload] = (state.collection[action.payload]?? 1) - 1
      }
      //Database Update
      api.post(
        "api/cardCollectionUpdate",
        { cardId: action.payload, qty: state.collection[action.payload] }
      )
    }))
    .addCase(addCardCollection, ((state, action) => {
      state.collection[action.payload] = (state.collection[action.payload] ?? 0) + 1
      //Database Update
      api.post(
        "api/cardCollectionUpdate",
        { cardId: action.payload, qty: state.collection[action.payload] }
      )
    }))
    .addCase(fetchCollection.fulfilled, (state, action) => {
      state.collection = action.payload?.cards ?? {};
    })
    
  },
});

//export const { increment, decrement, incrementByAmount } = cardsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const collection = (state) => state.collection.collection;
export const deckName = (state) => state.builder.name;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};*/

export default collectionSlice.reducer;
