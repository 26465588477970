import api from '../../utils/api'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'



export const fetchCollection = createAsyncThunk(
  'collection/fetchCollection',
  async () => {
    const response = await api.get("api/getCardCollection");
    // The value we return becomes the `fulfilled` action payload
    return response.data;

  }
);




export const removeCardCollection = createAction('collection/removeCard');
export const addCardCollection = createAction('collection/addCard');
