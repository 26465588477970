import {  createSlice } from '@reduxjs/toolkit';
import { postDeck, fetchDeck, fetchDecksHome } from './deckActions';

const initialState = { 
  name: '',
  deck:{},
  homeDecks: []
}

export const builderSlice = createSlice({
  name: 'deck',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {}, 
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeck.fulfilled, (state, action) => {
        state.deck = action.payload
      })
      .addCase(fetchDecksHome.fulfilled, (state, action) => {
        state.homeDecks = action.payload
      })
  },
});

//export const { increment, decrement, incrementByAmount } = cardsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDeck = (state) => state.deck.deck;
export const deckName = (state) => state.deck.name;
export const homeDecks = (state) => state.deck.homeDecks;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};*/

export default builderSlice.reducer;
