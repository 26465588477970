import api from '../../utils/api'
import {refresh as refreshSession} from '../../utils/api'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const registerUser =  createAsyncThunk(
  'auth/register',
  async ({ username, email, password }, { rejectWithValue }) => {
    try {
      const { data } =await api.post(
        "auth/register",
        { username, email, password }
      )
      if(data.message == 'REGISTER_FAILED'){
        return rejectWithValue(data.message) 
      }
      return data
    } catch (error) {
    // return custom error message from backend if present
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error)
      } else {
        return rejectWithValue(error.message) 
      }
    }
  }
)

export const loginUser =  createAsyncThunk(
  'auth/login',
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const { data } =await api.post(
        "auth/login",
        { username, password },
        { withCredentials: true }
      )

      return data
    } catch (error) {
      //return rejectWithValue("Wrong username or password")
      // return custom error message from backend if present
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const logout =  createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await api.post(
        "auth/logout",
        { },
        { withCredentials: true }
      )
      return true
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const restoreSession =  createAsyncThunk(
  'auth/restoreSession',
  async (_, { rejectWithValue }) => {
    try {
      return await refreshSession()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)