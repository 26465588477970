import React, { useEffect, useState  }  from 'react';

import CardElement from "../../components/Card";

import { useSelector, useDispatch } from 'react-redux';
import { selectCardList, filters as appliedFilters } from '../../features/cards/cardsSlice';
import { fetchCards, fetchSets } from '../../features/cards/cardsActions';
import { fetchCollection } from '../../features/Collection/collectionActions';

import { collection } from '../../features/Collection/collectionSlice';

import { List, Slider } from 'antd';
import FilterComponent from '../../components/FilterComponent';

import { CardUtils } from '../../utils/cardUtils';

export default function Cards() {

  const dispatch = useDispatch();

  const data = useSelector(selectCardList)
  const filters = useSelector(appliedFilters)
  const userCollection = useSelector(collection)

  const [gridsize, setGridsize] = useState(50);



  useEffect(()=>{
    dispatch(fetchSets())
    dispatch(fetchCards())
    dispatch(fetchCollection())
    }, [])

  return (
    <>
      <FilterComponent></FilterComponent>
      <div>
          <Slider min={1} defaultValue={gridsize} onAfterChange={(e)=>{setGridsize(e);this.forceUpdate()}} />
          <List
          key='cardlist'
            pagination={{ 
              position: 'both', 
              align: 'center',
              pageSize: 50,
              hideOnSinglePage: true,
              showSizeChanger: false
            }}
            grid={{ gutter: 16, xs: 2 , sm: 3 , md: 4 , lg: 6 , xl: 6 , xxl: 8  }}
            dataSource={data.filter((card)=>CardUtils.filterCard(card, filters)).sort((card1, card2)=>CardUtils.compare(card1, card2, filters))}
            renderItem={(item) => (
              <List.Item key={item.id}>
                  <CardElement key={item.id} card={item} cardCopy={(userCollection && userCollection[item.id])}/>
              </List.Item>
            )}
            rowKey={(item) => (item.id)}
            header = <div></div>
            size
          />
      </div> 
    </>
    
      

  );
}