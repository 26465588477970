import React from 'react';
import { LockOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, Alert } from 'antd';

import { registerUser } from '../../features/auth/authActions'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from "react-router-dom";

const RegisterElement = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { loading, registerError } = useSelector(
    (state) => state.auth
  )

  const onFinish = (values) => {
    dispatch(registerUser({username:values.username, email:values.email, password:values.password })).unwrap().then((result) => {
      navigate('/')
    }).catch((err) => {
    });
  };

  const renderErrorMsg = () => {
    if (!loading && registerError) {
      return <Form.Item><Alert message={registerError} type="error" /></Form.Item>;
    }
  }

  return (
    <Form
      name="localRegister"
      className="registerForm"
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your Username!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || Boolean(value.match(/^[A-Za-z0-9]*$/))) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Username must contains only numbers and letters!'));
            },
          }),
        ]}
      >
        <Input 
          prefix={<UserOutlined />} 
          placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input 
          prefix={<MailOutlined />} 
          placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >
        <Input.Password 
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password 
          prefix={<LockOutlined />}
          type="password"
          placeholder="Confirm password"/>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item>

      { renderErrorMsg() }

    </Form>
  );
};

export default RegisterElement;